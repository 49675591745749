import * as React from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import ProductsSubnav from '../../components/products_subnav'
import EsTeaser from '../../components/es_teaser'
import HeaderSpacer from '../../components/header_spacer'
import { StaticImage } from 'gatsby-plugin-image'
import Container from '../../components/container'
import Button from '../../components/button'
import { Link as GatsbyLink } from 'gatsby'
import DemoModal from '../../components/demo_modal'
import Link from '../../components/link'
import NumberedScroll from '../../components/numbered_scroll'
import FullyLoadedImage1 from '../../images/fullyloaded_em_deal-building.png'
import FullyLoadedImage2 from '../../images/fullyloaded_em_Service-protection-plans.png'
import EmHeroImage from '../../components/em_hero_image'

const DealerGroupSolutionPage = () => (
  <Layout activeNav={true}>
    <Seo
      title='Dealer Group Solution'
      description='Extend the power of our platform across your entire dealer group. Drive traffic more efficiently to an interactive online marketplace where car buyers can shop cross-brand inventory and build deals.'
    />
    <HeaderSpacer dark />
    <ProductsSubnav />
    <div className='flex flex-col justify-center relative' style={{ minHeight: 675 }}>
      <StaticImage
        src='../../images/em_hero_bg.jpg'
        alt=''
        placeholder='blurred'
        className='absolute inset-0'
      />
      <Container className='relative'>
        <div className='pt-4 md:pt-0 grid items-center md:gap-3 md:grid-cols-2 lg:gap-4 text-center md:text-left'>
          <div>
            <p className='text-gray uppercase font-semi-bold text-base tracking-wide leading-none'>
              Dealer Group Solution
            </p>
            <h1 className='mt-0 font-semi-bold text-4vw tracking-tight' style={{ marginLeft: '-.08em' }}>
              Express Marketplace<sup className='tm font-light'>®</sup>
            </h1>
            <p className='text-base sm:text-md lg:text-lg sm:leading-relaxed mb-1/2em'>
              Extend the power of our platform across your entire dealer group. Drive traffic more efficiently to an interactive online marketplace where car buyers can shop cross-brand inventory and build deals.
            </p>
            <p className='italic text-base sm:text-base lg:text-md text-gray'>
              Express Storefront Required
            </p>
            <div className='mt-3 sm:mt-4'>
              <DemoModal>
                <Button
                  variant='dark'
                  minWidth={true}
                  children='Request Demo'
                />
              </DemoModal>
            </div>
          </div>
          <div>
            <EmHeroImage />
          </div>
        </div>
      </Container>
    </div>
    <div className='Section pt-0'>
      <Container>
        <div className='grid gap-3 sm:gap-5 sm:grid-cols-2 lg:grid-cols-3 leading-relaxed'>
          <div>
            <h2 className='font-light text-xl sm:text-2xl mt-0'>
              <img
                alt=''
                src='https://media.roadster.com/dealersales/EM_Icon_1.1.svg'
                height='82'
                width='66'
                className='block mb-2'
                style={{ marginLeft: '.05em' }}
              />
              Commerce enabled
            </h2>
            <p className='text-base sm:text-md mb-0'>
              Transform your dealer group site into a fully interactive marketplace. There, car buyers can begin building deals that they can seamlessly complete on a dealer’s Express Store or in their showroom. 
            </p>
          </div>
          <div>
            <h2 className='font-light text-xl sm:text-2xl mt-0'>
              <img
                alt=''
                src='https://media.roadster.com/dealersales/EM_Icon_2.1.svg'
                height='82'
                width='66'
                className='block mb-2'
                style={{ marginLeft: '.07em' }}
              />
              Cross-brand inventory
            </h2>
            <p className='text-base sm:text-md mb-0'>
              Enable car buyers to shop your dealer group’s entire portfolio of cross-brand inventory, find the ride that’s right for them, and take the next steps to make it their own.
            </p>
          </div>
          <div>
            <h2 className='font-light text-xl sm:text-2xl mt-0'>
              <img
                alt=''
                src='https://media.roadster.com/dealersales/EM_Icon_3.1.svg'
                height='82'
                width='66'
                className='block mb-2'
                style={{ marginLeft: '-.05em' }}
              />
              More efficient to market
            </h2>
            <p className='text-base sm:text-md mb-0'>
              Pool your dealer group’s marketing dollars to drive traffic more efficiently to one robust automotive marketplace.
            </p>
          </div>
        </div>
      </Container>
    </div>
    <div className='Section Section--stripe pb-0'>
      <Container size='lg'>
        <h2 className='text-2xl sm:text-3xl font-light mt-0 text-center'>
          Ready for a Test Drive?
        </h2>
        <div className='text-md text-center mb-4 mx-auto' style={{ maxWidth: '56em' }}>
          If you’re ready to integrate Express Marketplace into your dealer group’s website — or if you’d like more info or a live demo — reach out to us at
          {' '}
          <Link
            href='mailto:dealersales@roadster.com'
            variant='muted'
            className='font-semi-bold'
            children='dealersales@roadster.com'
          />.
        </div>
        <div className='flex justify-center sm:-mb-3 lg:-mb-4 relative z-1'>
          <DemoModal>
            <Button
              variant='dark'
              minWidth={true}
              children='Request a Demo'
            />
          </DemoModal>
        </div>
      </Container>
      <div className='flex justify-center'>
        <StaticImage
          alt=''
          src='../../images/em_car_crop.png'
          width={1440}
          placeholder='blurred'
        />
      </div>
    </div>
    <div className='Section pb-4'>
      <Container size='lg'>
        <h2 className='text-2xl sm:text-3xl font-light text-center mt-0'>
          Fully Loaded
        </h2>
        <p className='text-md text-center'>
          Express Marketplace comes with everything customers need to begin fully transacting from your group site.
        </p>
        <NumberedScroll
          items={[
            {
              title: 'Deal-building tools',
              desc: 'Empower customers to build their own car deal right from your Express Marketplace, then seamlessly pick up where they left off on a dealer’s Express Store or in their showroom.',
              image: FullyLoadedImage1
            },
            {
              title: 'Multiple payment options',
              desc: 'Show customers exactly what they’ll owe for their vehicle (including taxes, fees and discounts applied), and let them choose how they want to pay for it.'
            },
            {
              title: 'Easy trade valuation',
              desc: 'Enable customers to get a firm offer on their trade-in vehicle and determine their loan payoff using Roadster’s Express Trade®, or use a trade solution of your choice<sup>1</sup>'
            },
            {
              title: 'Service and protection plans',
              desc: 'Drive more revenue by prominently promoting your service and protection plans throughout the car-shopping experience.',
              image: FullyLoadedImage2
            },
            {
              title: 'Optional accessories store',
              desc: 'Entice car buyers with add-ons — from cargo nets to cross bars — and watch your profits grow.'
            },
            {
              title: 'Secure credit application',
              desc: 'Enable car buyers to submit an online credit application and upload a photo of their driver’s license. Everything’s encrypted and secured by two-factor authentication.'
            }
          ]}
        />
        <div className='mt-4 sm:mt-5 text-gray text-center'>
          1. The following third-party trade solutions are available for integration:
          <br className='hidden sm:block' />
          AutoHub, Edmunds TMV, Kelley Blue Book ICO, Kelley Blue Book Trade-In Value, TradePending, TrueCar.
        </div>
      </Container>
    </div>
    <div className='relative bg-dark-gray py-4'>
      <StaticImage
        alt=''
        src='../../images/customerstories_bg.jpg'
        placeholder='blurred'
        className='absolute inset-0'
      />
      <Container size='lg' className='relative'>
        <div className='text-white text-center'>
          <div className='font-semi-bold leading-none text-silver font-serif' style={{ fontSize: '6rem' }}>“</div>
          <div className='Testimonial' style={{ maxWidth: '30em' }}>
            Partnering with Roadster was the obvious choice, as it’s the only commerce solution in the marketplace that empowers dealers to offer all of their in-store services for car buying online.
          </div>
          <div className='mt-3 text-md'>
            Brian Benstock, VP
          </div>
          <div className='text-md font-semi-bold'>
            Paragon Honda & Acura
          </div>
          <div className='flex items-center leading-none mb-4 mt-5'>
            <span className='flex-1 border-t border-gray' />
            <span className='mx-2 uppercase tracking-wide text-base sm:text-md font-semi-bold'>
              More Customer Stories
            </span>
            <span className='flex-1 border-t border-gray' />
          </div>
        </div>
        <div className='grid gap-3 sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
          <GatsbyLink
            to='/customer_stories/sunautogroup'
            className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
            <div className='p-2 sm:p-3'>
              <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                Sun Automotive Group
              </div>
              <div className='text-md sm:text-lg text-gray'>
                Automotive Advisors are the single point of contact for the entire sales process.
              </div>
            </div>
            <div className='relative mt-auto'>
              <img
                alt=''
                loading='lazy'
                src='https://media.roadster.com/stories/sunautogroup/thumb.jpg'
                className='block w-full'
              /> 
              <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                <Button
                  as='span'
                  variant='outline'
                  minWidth={true}
                  children='View Story'
                />
              </div>
            </div>
          </GatsbyLink>
          <GatsbyLink
            to='/customer_stories/audirocklin'
            className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
            <div className='p-2 sm:p-3'>
              <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                Audi Rocklin
              </div>
              <div className='text-md sm:text-lg text-gray'>
                Transparent customer experiences that lead to higher gross and CSI.
              </div>
            </div>
            <div className='relative mt-auto'>
              <img
                alt=''
                loading='lazy'
                src='https://media.roadster.com/stories/audirocklin/thumb.jpg'
                className='block w-full'
              /> 
              <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                <Button
                  as='span'
                  variant='outline'
                  minWidth={true}
                  children='View Story'
                />
              </div>
            </div>
          </GatsbyLink>
          <GatsbyLink
            to='/customer_stories/longotoyota'
            className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
            <div className='p-2 sm:p-3'>
              <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                Longo Toyota
              </div>
              <div className='text-md sm:text-lg text-gray'>
                Digitizing every step of the sales process.
              </div>
            </div>
            <div className='relative mt-auto'>
              <img
                alt=''
                loading='lazy'
                src='https://media.roadster.com/stories/longotoyota/thumb.jpg'
                className='block w-full'
              /> 
              <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                <Button
                  as='span'
                  variant='outline'
                  minWidth={true}
                  children='View Story'
                />
              </div>
            </div>
          </GatsbyLink>
        </div>
      </Container>
    </div>
    <EsTeaser />
  </Layout>
)

export default DealerGroupSolutionPage
