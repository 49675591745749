import React, { useEffect } from 'react'
import { media } from '../styles/stitches.config'
import Box from './box'
import Link from './link'
const ACTIVE_CLASS = 'is-active'
const IMG_OFFSET = 18

const NumberedScroll = ({ items }) => {

  useEffect(() => {

    if (window.matchMedia(media.sm).matches) {

      const offset =
        document.getElementById('header').offsetHeight +
        document.getElementById('subnav').offsetHeight +
        IMG_OFFSET

      const sourceElements = []
      const targetElements = []

      const throttle = (fn, wait = 100) => {
        let timer
        let time = Date.now()

        return (params) => {
          clearTimeout(timer)

          if (time + wait - Date.now() < 0) {
            fn(params)
            time = Date.now()
          } else {
            timer = setTimeout(fn, wait / 5)
          }
        }
      }

      const onScrollHandler = throttle(() => {

        sourceElements.map((source, i) => {

          const sourceRect = source.getBoundingClientRect()
          const target = targetElements[i]
          
          if (sourceRect.y < offset + target.offsetHeight) {
            target.classList.add(ACTIVE_CLASS)
            targetElements[i - 1]?.classList.remove(ACTIVE_CLASS)
            source.classList.add(ACTIVE_CLASS)
            sourceElements[i - 1]?.classList.remove(ACTIVE_CLASS)
          } else {
            target.classList.remove(ACTIVE_CLASS)
            source.classList.remove(ACTIVE_CLASS)
          }
          
          return true
        })
      })

      items.map((item, itemIndex) => {

        if (!item.image) {
          return null
        }
        
        const sourceElement = document.getElementById(`numberedscroll_${itemIndex}`)
        const targetElement = document.getElementById(`numberedscroll_img_${itemIndex}`)
        
        if (sourceElement && targetElement) {
          sourceElements.push(sourceElement)
          targetElements.push(targetElement)
        }

        return true
      })

      const ScrollEvent = new Event('scroll')
      window.addEventListener('scroll', onScrollHandler, { passive: true })
      window.dispatchEvent(ScrollEvent)

      return () => {
        window.removeEventListener('scroll', onScrollHandler)
      }
    }

  })

  return (
    <div className='grid items-start sm:grid-cols-2 gap-4 md:gap-5'>
      <div
        className='sticky xs:hidden'
        style={{ top: `calc(var(--header-height) + var(--subnav-height) + ${IMG_OFFSET}px)` }}>
        <div
          className='relative'
          style={{ paddingBottom: '100%' }}
        >
          {items.map((item, imgIndex) => item.image && (
            <Box
              key={`numberedscroll_img_${imgIndex}`}
              id={`numberedscroll_img_${imgIndex}`}
              className='bg-white absolute inset-0 transition-opacity duration-300'
              css={{
                opacity: 0,
                '& img': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  '&:not(:first-child)': {
                    opacity: 0
                  }
                },
                '&.is-active': {
                  opacity: 1,
                  '& img:not(:first-child)': {
                    opacity: 1,
                    transform: 'none'
                  }
                }
              }}>
              {typeof item.image === 'string' ?
                <img
                  alt=''
                  src={item.image}
                  loading='lazy'
                /> : item.image}
            </Box>
          ))}
        </div>
      </div>
      <div className='text-center sm:text-left mt-4 sm:mt-5 grid gap-3 sm:gap-5 text-md lg:text-lg lg:font-light leading-relaxed'>
        {items.map((item, index) =>
          <Box
            key={`numberedscroll_${item.title}`}
            id={`numberedscroll_${index}`}
            css={{
              '&.is-active .CircleNum': {
                color: '$white',
                backgroundColor: '$black',
                borderColor: '$black'
              }
            }}>
            <div className='mx-auto sm:ml-0 CircleNum CircleNum--lg mb-3 transition-color duration-300'>
              {index + 1}
            </div>
            <h3
              className='text-em mb-3'
              dangerouslySetInnerHTML={{
                __html: item.title
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: item.desc
              }}
            />
            {item.link &&
              <Link
                href='https://go.roadster.com/dealer-impact-study-2.0'
                arrowDirection='right'
                variant='muted'
                className='font-semi-bold text-md'
                children='Learn more'
              />
            }
          </Box>
        )}
      </div>
    </div>
  )
}

export default NumberedScroll
