import { styled } from '../styles/stitches.config'

const HeaderSpacer = styled('div', {
  height: 'var(--header-height)',
  variants: {
    dark: {
      true: {
        backgroundColor: '$black'
      }
    }
  }
})

export default HeaderSpacer
