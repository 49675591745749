import * as React from 'react'
import Subnav from './subnav'

const ProductsSubnav = () => (
  <Subnav
    isSticky={true}
    links={[
      {
        to: '/products/digital_retailing',
        text: 'Digital Retailing'
      },
      {
        to: '/products/internet_lead_response',
        text: 'Internet Lead Response'
      },
      {
        to: '/products/live_chat',
        text: 'Live Chat'
      },
      {
        to: '/products/trade_in_appraisal',
        text: 'Trade-in Appraisal'
      },
      {
        to: '/products/dealer_group_solution',
        text: 'Dealer Group Solution'
      },
      {
        to: '/products/product_updates',
        text: 'Product Updates'
      }
    ]}
  />
)

export default ProductsSubnav
