import React, { useRef, useEffect } from 'react'
import Image1 from '../images/products_em_01.png'
import Image2 from '../images/products_em_02.png'
import Image3 from '../images/products_em_03.png'

const EmHeroImage = () => {

  const image1Ref = useRef(null)
  const image2Ref = useRef(null)
  const image3Ref = useRef(null)

  useEffect(() => {
    let timer1, timer2
    image3Ref.current.src = Image3
    image3Ref.current.onload = () => {
      Object.assign(image1Ref.current.style, {
        transform: 'none',
        opacity: 1
      })
      timer1 = setTimeout(() => {
        Object.assign(image2Ref.current.style, {
          transform: 'none',
          opacity: 1
        })
      }, 750)
      timer2 = setTimeout(() => {
        Object.assign(image3Ref.current.style, {
          transform: 'none',
          opacity: 0.1
        })
      }, 1500)
    }
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  })

  return (
    <div className='relative overflow-hidden'>
      <div style={{ paddingBottom: '100%' }} />
      <img
        ref={image1Ref}
        src={Image1}
        alt=''
        style={{ opacity: 0, transform: 'scale(90%)' }}
        className='absolute w-full h-full top-0 left-0 transition duration-500'
      />
      <img
        ref={image3Ref}
        alt=''
        style={{ opacity: 0, transform: 'scale(90%)' }}
        className='absolute w-full h-full top-0 left-0 transition duration-500'
      />
      <img
        ref={image2Ref}
        src={Image2}
        alt=''
        style={{ opacity: 0, transform: 'translateY(-5%)' }}
        className='absolute w-full h-full top-0 left-0 transition duration-500'
      />
    </div>
  )
}

export default EmHeroImage
