import * as React from 'react'
import Container from './container'
import Box from './box'
import { StaticImage } from 'gatsby-plugin-image'
import Button from './button'
import DemoForm from './demo_form'

const COPY = 'Roadster’s digital sales platform provides a modern retail experience for customers and dealers alike. Its omnichannel capabilities enable seamless end-to-end transactions online and in store.'

const CTA = 
  <Button
    to='/products/digital_retailing'
    variant='dark'
    children='Learn More'
    minWidth={true}
  />

const EsTeaser = () => (<>
  <div className='Section Section--dark'>
    <Container>
      <DemoForm invert={true} />
    </Container>
  </div>
  <section className='Section pb-0 text-center leading-relaxed pt-4 sm:pt-0 overflow-hidden'>
    <Box
      css={{ '@lg': { top: '-9%' } }}
      className='absolute top-0 left-0 w-full'
    >
      <StaticImage
        src='../images/es_teaser_bg.jpg'
        alt=''
        placeholder='blurred'
        quality={100}
      />
    </Box>
    <Container>
      <div className='relative mx-auto' style={{ maxWidth: 1024 }}>
        <StaticImage
          src='../images/es_teaser.png'
          alt=''
          placeholder='blurred'
        />
        <Box
          className='absolute left-0 w-full'
          css={{
            top: '11%',
            '@media (min-width: 450px)': {
              top: '13%'
            },
            '@sm': {
              top: '25%'
            }
          }}>
          <Box
            as='h1'
            css={{
              fontSize: '8vw'
            }}
            className='sm:text-4vw font-semi-bold mt-0 mb-1/4em'>
            Express <br className='sm:hidden' />Storefront<sup className='tm font-light'>®</sup>
          </Box>
          <div className='xs:hidden'>
            <p className='mx-auto mb-3 text-base md:text-md' style={{ maxWidth: '58%' }}>
              {COPY}
            </p>
            {CTA}
          </div>
        </Box>
        <div className='sm:hidden pb-4' style={{ marginTop: '-5%' }}>
          <p className='mx-auto mb-3 text-base md:text-md'>
            {COPY}
          </p>
          {CTA}
        </div>
      </div>
    </Container>
  </section>
</>)

export default EsTeaser
